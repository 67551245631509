import type { JSX, PropsWithChildren } from 'react';

import { Text } from '@change-corgi/design-system/typography';

import { useQRCode } from 'src/app/shared/hooks/qrcode';

type QRCodeDownloadLinkProps = PropsWithChildren<{
	value: string;
	size?: number;
	filename?: string;
	onDownload?: () => Promise<void>;
}>;
/**
 * Renders a link to download a QR code with the given value.
 * The QRCode is not visible, but is used to download a hi-res version of the QRCode
 */
export function QRCodeDownloadLink({
	children,
	value,
	size = 512,
	filename,
	onDownload,
}: QRCodeDownloadLinkProps): JSX.Element {
	const { data } = useQRCode({ value, size });

	const handleDownload = async () => {
		await onDownload?.();
	};

	return (
		// eslint-disable-next-line react/forbid-elements
		<a
			href={data ?? '#'}
			download={filename || true}
			onClick={handleDownload}
			sx={{ fontWeight: 'bold', color: 'secondary-blue' }}
			data-qa="qrcode-download-link"
			data-testid="qrcode-download-link"
		>
			<Text size="small">{children}</Text>
		</a>
	);
}
