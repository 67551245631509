import type { JSX } from 'react';

import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { QRCode } from './components/QRCode';
import { QRCodeDownloadLink } from './components/QRCodeDownloadLink';

type QRCodeDownloadProps = {
	value: string;
	descriptionText: string;
	downloadLinkText: string;
	downloadFilename?: string;
	onDownload?: () => Promise<void>;
};
export function QRCodeDownload({
	value,
	descriptionText,
	downloadLinkText,
	downloadFilename,
	onDownload,
}: QRCodeDownloadProps): JSX.Element {
	return (
		<Flex sx={{ gap: '16' }}>
			<QRCode value={value} includeMargin={false} sx={{ flexShrink: 0 }} />
			<Flex sx={{ flexDirection: 'column', gap: 8 }}>
				<Text>{descriptionText}</Text>
				<QRCodeDownloadLink value={value} onDownload={onDownload} filename={downloadFilename}>
					{downloadLinkText}
				</QRCodeDownloadLink>
			</Flex>
		</Flex>
	);
}
